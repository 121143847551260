import { configureStore } from '@reduxjs/toolkit';

/* Import state slices */
import layoutSlice from './layout/layout';
import authSlice from './auth/auth';
import globalFeedbackSlice from './globalFeedback/globalFeedback';
import qrCodeSlice from './qrcode/qrcode';

const store = configureStore({
    reducer: {
        layout: layoutSlice.reducer,
        auth: authSlice.reducer,
        globalFeedback: globalFeedbackSlice.reducer,
        qrCode: qrCodeSlice.reducer,
    },
});

export default store;