import { createSlice } from '@reduxjs/toolkit';

/* Set up initial state */
const initialAuthState = {
    token: localStorage.getItem('token') !== null ? localStorage.getItem('token') : null,
    refreshToken: localStorage.getItem('refreshToken') !== null ? localStorage.getItem('refreshToken') : null,
    phone: localStorage.getItem('phone') !== null ? localStorage.getItem('phone') : null,
    firstName: localStorage.getItem('firstName') !== null ? localStorage.getItem('firstName') : null,
    lastName: localStorage.getItem('lastName') !== null ? localStorage.getItem('lastName') : null,
    organizationName: localStorage.getItem('organizationName') !== null ? localStorage.getItem('organizationName') : null,
    organizationId: localStorage.getItem('organizationId') !== null ? parseInt(localStorage.getItem('organizationId')) : null,
    userRoles: localStorage.getItem('userRoles') !== null ? JSON.parse(localStorage.getItem('userRoles')) : null,
};

/* Set up slice */
const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        login(state, action) {
            /* Populate user role array */
            const userRoles = action.payload.roles.map((item) => {
                return item.pk;
            });

            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('refreshToken', action.payload.refreshToken);
            localStorage.setItem('phone', action.payload.phone);
            localStorage.setItem('firstName', action.payload.firstName);
            localStorage.setItem('lastName', action.payload.lastName);
            localStorage.setItem('organizationName', action.payload.organizationName);
            localStorage.setItem('organizationId', action.payload.organizationId);
            localStorage.setItem('userRoles', JSON.stringify(userRoles));
            
            state.token = action.payload.token;
            state.refreshToken = action.payload.refreshToken;
            state.phone = action.payload.phone;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.organizationName = action.payload.organizationName;
            state.organizationId = action.payload.organizationId;
            state.userRoles = userRoles;
        },
        logout(state) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('phone');
            localStorage.removeItem('firstName');
            localStorage.removeItem('lastName');
            localStorage.removeItem('organizationName');
            localStorage.removeItem('organizationId');
            localStorage.removeItem('userRoles');

            state.token = null;
            state.refreshToken = null;
            state.phone = null;
            state.firstName = null;
            state.lastName = null;
            state.organizationName = null;
            state.organizationId = null;
            state.userRoles = null;
        },
    }
});

export const authActions = authSlice.actions;
export default authSlice;