import { createSlice } from '@reduxjs/toolkit';

/* Set up initial state */
const visitorQrInitialState = {
    qrCode: "",
};

/* Set up slice */
const qrCodeSlice = createSlice({
    name: 'qrCode',
    initialState: visitorQrInitialState,
    reducers: {
        setQrCode(state, action) {
            state.qrCode = action.payload.qrCode;
        }
    }
});

export const qrCodeActions = qrCodeSlice.actions;
export default qrCodeSlice;