/* Layout constants */
export const LAYOUT_VERTICAL = 'vertical';

export const LAYOUT_COLOR_LIGHT = 'light';

export const LAYOUT_WIDTH_FLUID = 'fluid';

export const LEFT_SIDEBAR_THEME_LIGHT = 'light';

export const LEFT_SIDEBAR_TYPE_FIXED = 'fixed';

export const LEFT_SIDEBAR_TYPE_CONDENSED = 'condensed';

export const LEFT_SIDEBAR_TYPE_SCROLLABLE = 'scrollable';