/* Package imports */
import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

/* Utility imports */
import { GlobalValues } from "../constants/global";

/* Import layouts/containers */
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";

/* Import root component */
import Root from "./Root";
import { useSelector } from "react-redux";

/* Lazy load all views */
// Error views
const PageNotFound = React.lazy(() => import("../pages/PageNotFound"));
// Auth views
const Login = React.lazy(() => import("../pages/account/Login"));
const Logout = React.lazy(() => import("../pages/account/Logout"));
// Dashboard
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
// Visitor
const ManageUpdateVisitorDetails = React.lazy(() => import("../pages/Visitor/ManageUpdateVisitorDetails"));
const VisitorDetailsUpdated = React.lazy(() => import("../pages/Visitor/VisitorDetailsUpdated"));
const VisitorDetailsNotUpdated = React.lazy(() => import("../pages/Visitor/VisitorDetailsNotUpdated"));
const ManageWalkInVisitor = React.lazy(() => import("../pages/Visitor/ManageWalkInVisitor"));
const WalkinVisitorDetailsCreated = React.lazy(() => import("../pages/Visitor/WalkinVisitorDetailsCreated"));
const ViewTermsAndConditionsManage = React.lazy(() => import("../pages/ViewTermsAndConditionsManage"));
const WalkInVisitorUpdateDetailsForm = React.lazy(() => import("../pages/Visitor/ManageWalkInVisitorUpdateDetails"));

/* Super Admin */
// User
const ManageUserSuperAdmin = React.lazy(() => import("../pages/superAdmin/user/ManageUser"));
const ListUserSuperAdmin = React.lazy(() => import("../pages/superAdmin/user/ListUser"));
// Organization
const ManageOrganization = React.lazy(() => import("../pages/superAdmin/organization/ManageOrganization"));
const ListOrganization = React.lazy(() => import("../pages/superAdmin/organization/ListOrganization"));
// Organization User
const ListUserLinkedOrganizationManage = React.lazy(() => import("../pages/superAdmin/user/userOrganization/ListUserLinkedOrganizationManage"));
const ManageUserOrganization = React.lazy(() => import("../pages/superAdmin/user/userOrganization/ManageUserOrganization"));
// NDA
const ListNDA = React.lazy(() => import("../pages/superAdmin/NDA/ListNDA"));
const ManageNDA = React.lazy(() => import("../pages/superAdmin/NDA/ManageNDA"));

/* Organization Admin */
// User
const ManageUserOrganizationAdmin = React.lazy(() => import("../pages/organizationAdmin/user/ManageUser"));
const ListUserOrganizationAdmin = React.lazy(() => import("../pages/organizationAdmin/user/ListUser"));
// Establishment
const ManageEstablishment = React.lazy(() => import("../pages/organizationAdmin/establishment/ManageEstablishment"));
const ListEstablishment = React.lazy(() => import("../pages/organizationAdmin/establishment/ListEstablishment"));
// Establishment Admin
const ListAdminLinkedEstablishmentManage = React.lazy(() =>
    import("../pages/organizationAdmin/user/establishmentAdmin/ListAdminLinkedEstablishmentManage")
);
const ManageAdminEstablishment = React.lazy(() => import("../pages/organizationAdmin/user/establishmentAdmin/ManageAdminEstablishment"));
// Organization Employee
const ManageEmployeeOrganization = React.lazy(() => import("../pages/organizationAdmin/user/organizationEmployee/ManageEmployeeOrganization"));
const ListEmployeeLinkedOrganizationManage = React.lazy(() =>
    import("../pages/organizationAdmin/user/organizationEmployee/ListEmployeeLinkedOrganizationManage")
);
//Service Booking
const ServiceBookingManage = React.lazy(() => import("../pages/organizationAdmin/serviceBooking/ServiceBookingManage"));
// Service Category
const ManageServiceCategory = React.lazy(() => import("../pages/organizationAdmin/serviceCategory/ManageServiceCategory"));
const ListServiceCategory = React.lazy(() => import("../pages/organizationAdmin/serviceCategory/ListServiceCategory"));
// Service Sub-Category
const ManageServiceSubCategory = React.lazy(() => import("../pages/organizationAdmin/serviceSubCategory/ManageServiceSubCategory"));
const ListServiceSubCategory = React.lazy(() => import("../pages/organizationAdmin/serviceSubCategory/ListServiceSubCategory"));
// Service
const ManageService = React.lazy(() => import("../pages/organizationAdmin/service/ManageService"));
const ListService = React.lazy(() => import("../pages/organizationAdmin/service/ListService"));
// Service Request
const UpdateServiceRequest = React.lazy(() => import("../pages/organizationAdmin/serviceBooking/UpdateServiceRequest/UpdateServiceRequest"));
// Service Slot
const ListServiceSlotManage = React.lazy(() => import("../pages/organizationAdmin/service/serviceSlot/ListServiceSlotManage"));
const ManageServiceSlot = React.lazy(() => import("../pages/organizationAdmin/service/serviceSlot/ManageServiceSlot"));
// Service Exclusion
const ListServiceExclusionManage = React.lazy(() => import("../pages/organizationAdmin/service/serviceExclusion/ListServiceExclusionManage"));
const ManageServiceExclusion = React.lazy(() => import("../pages/organizationAdmin/service/serviceExclusion/ManageServiceExclusion"));
// Employee Category
const ManageEmployeeCategory = React.lazy(() => import("../pages/employeeCategory/ManageEmployeeCategory"));
const ListEmployeeCategory = React.lazy(() => import("../pages/employeeCategory/ListEmployeeCategory"));

/* Establishment Admin */
// User
const ListUserManageEstablishmentAdmin = React.lazy(() => import("../pages/establishmentAdmin/establishment/user/ListUserManage"));
const ManageUserEstablishmentAdmin = React.lazy(() => import("../pages/establishmentAdmin/establishment/user/ManageUser"));
// Establishment
const ListAdminLinkedEstablishment = React.lazy(() => import("../pages/establishmentAdmin/establishment/ListAdminLinkedEstablishment"));
//Emergency Broadcast
const ManageEmergencyBroadcastEstablishment = React.lazy(() => import("../pages/establishmentAdmin/establishment/emergency/ManageEmergency"));
// Amenity
const ListAmenityManage = React.lazy(() => import("../pages/establishmentAdmin/establishment/amenity/ListAmenityManage"));
const ManageAmenity = React.lazy(() => import("../pages/establishmentAdmin/establishment/amenity/ManageAmenity"));
// Amenity Exclusion
const ListAmenityExclusionManage = React.lazy(() =>
    import("../pages/establishmentAdmin/establishment/amenity/amenityExclusion/ListAmenityExclusionManage")
);
const ManageAmenityExclusion = React.lazy(() => import("../pages/establishmentAdmin/establishment/amenity/amenityExclusion/ManageAmenityExclusion"));
// Amenity Slot
const ListAmenitySlotManage = React.lazy(() => import("../pages/establishmentAdmin/establishment/amenity/amenitySlot/ListAmenitySlotManage"));
const ManageAmenitySlot = React.lazy(() => import("../pages/establishmentAdmin/establishment/amenity/amenitySlot/ManageAmenitySlot"));
// Amenity Booking
const EstablishmentAmenityBookingManage = React.lazy(() =>
    import("../pages/establishmentAdmin/establishment/amenityBooking/EstablishmentAmenityBookingManage")
);
// Security Guard Attendance
const SecurityGuardAttendanceManage = React.lazy(() => import("../pages/establishmentAdmin/establishment/attendance/SecurityGuardAttendanceManage"));
// Building
const ListBuildingManage = React.lazy(() => import("../pages/establishmentAdmin/establishment/building/ListBuildingManage"));
const ManageBuilding = React.lazy(() => import("../pages/establishmentAdmin/establishment/building/ManageBuilding"));
// Unit
const ListUnitManage = React.lazy(() => import("../pages/establishmentAdmin/establishment/building/unit/ListUnitManage"));
const ManageUnit = React.lazy(() => import("../pages/establishmentAdmin/establishment/building/unit/ManageUnit"));
// Security Guard
const ListSecurityGuardLinkedEstablishmentManage = React.lazy(() =>
    import("../pages/establishmentAdmin/establishment/user/securityGuard/ListSecurityGuardLinkedEstablishmentManage")
);
const ManageSecurityGuard = React.lazy(() => import("../pages/establishmentAdmin/establishment/user/securityGuard/ManageSecurityGuard"));
// Company
const ListCompanyManage = React.lazy(() => import("../pages/establishmentAdmin/establishment/company/ListCompanyManage"));
const ManageCompany = React.lazy(() => import("../pages/establishmentAdmin/establishment/company/ManageCompany"));
// Company Unit
const ListCompanyUnitManage = React.lazy(() => import("../pages/establishmentAdmin/establishment/company/unit/ListUnitManage"));
const ManageCompanyUnit = React.lazy(() => import("../pages/establishmentAdmin/establishment/company/unit/ManageUnit"));
//Company Employee Admin
const ListCompanyEmployeeAdminLinkedCompanyManage = React.lazy(() =>
    import("../pages/establishmentAdmin/establishment/user/companyEmployeeAdmin/ListCompanyManage")
);
const ManageCompanyEmployeeAdmin = React.lazy(() =>
    import("../pages/establishmentAdmin/establishment/user/companyEmployeeAdmin/ManageCompanyLinking")
);
//Emergency Broadcast
const ManageEmergencyBroadcast = React.lazy(() => import("../pages/companyEmployeeAdmin/company/emergency/ManageEmergency"));

/* Company Employee Admin */
// User
const ListUserCompanyEmployeeAdminCompanyManage = React.lazy(() => import("../pages/companyEmployeeAdmin/company/user/ListUserManage"));
const ManageUserCompanyEmployeeAdminCompany = React.lazy(() => import("../pages/companyEmployeeAdmin/company/user/ManageUser"));
// Company
const ListCompanyEmployeeAdminCompany = React.lazy(() => import("../pages/companyEmployeeAdmin/company/ListCompany"));
// Company Amenity
const ListCompanyAmenityManage = React.lazy(() => import("../pages/companyEmployeeAdmin/company/amenity/ListAmenityManage"));
const ManageCompanyAmenity = React.lazy(() => import("../pages/companyEmployeeAdmin/company/amenity/ManageAmenity"));
// Amenity Exclusion
const ListCompanyAmenityExclusionManage = React.lazy(() =>
    import("../pages/companyEmployeeAdmin/company/amenity/amenityExclusion/ListAmenityExclusionManage")
);
const ManageCompanyAmenityExclusion = React.lazy(() =>
    import("../pages/companyEmployeeAdmin/company/amenity/amenityExclusion/ManageAmenityExclusion")
);
// Amenity Slot
const ListCompanyAmenitySlotManage = React.lazy(() => import("../pages/companyEmployeeAdmin/company/amenity/amenitySlot/ListAmenitySlotManage"));
const ManageCompanyAmenitySlot = React.lazy(() => import("../pages/companyEmployeeAdmin/company/amenity/amenitySlot/ManageAmenitySlot"));
// Amenity Booking
const CompanyAmenityBookingManage = React.lazy(() =>
    import("../pages/companyEmployeeAdmin/company/amenityBooking/CompanyAmenityBookingManage")
);
// Company Employee
const ListEmployeeCompanyEmployeeAdminCompanyManage = React.lazy(() =>
    import("../pages/companyEmployeeAdmin/company/user/employee/ListEmployeeManage")
);
const ManageEmployeeCompanyEmployeeAdminCompany = React.lazy(() => import("../pages/companyEmployeeAdmin/company/user/employee/ManageEmployee"));
// Company Employee Unit
const ManageEmployeeUnitCompanyEmployeeAdminCompany = React.lazy(() => import("../pages/companyEmployeeAdmin/company/user/employee/unit/ManageUnit"));
// Company Visitor List
const CompanyVisitorRecordsManage = React.lazy(() => import("../pages/companyEmployeeAdmin/company/visitorRecords/CompanyVisitorRecordsManage"));
// Terms and Conditions

const ListTermsAndConditionsManage = React.lazy(() =>
    import("../pages/companyEmployeeAdmin/company/TermsAndConditions/ListTermsAndConditionsManage")
);
const ManageTermsAndConditions = React.lazy(() => import("../pages/companyEmployeeAdmin/company/TermsAndConditions/ManageTermsAndConditions"));

/* Define loading component (fallback to suspenseful loading) */
const loading = () => <div className=""></div>;

/* Utility method for loading component */
const LoadComponent = ({ component: Component }) => {
    return (
        <Suspense fallback={loading()}>
            <Component />
        </Suspense>
    );
};

/* Declare all app routes */
const AllRoutes = () => {
    let Layout = VerticalLayout;
    const auth = useSelector(state => {
        return state.auth;
    });

    return useRoutes([
        /* Root component which navigates to the required URL */
        { path: "/", element: <Root /> },
        {
            /* Public route */
            path: "/",
            element: <DefaultLayout />,
            children: [
                {
                    path: "account",
                    children: [
                        { path: "login", element: <LoadComponent component={Login} /> },
                        { path: "logout", element: <LoadComponent component={Logout} /> }
                    ]
                },
                {
                    path: "terms-and-conditions",
                    element: <LoadComponent component={ViewTermsAndConditionsManage} />
                },
                {
                    path: "establishment/walkin-visitor",
                    children: [
                        {
                            path: "created",
                            element: <LoadComponent component={WalkinVisitorDetailsCreated} />
                        },
                        {
                            path: "",
                            element: <LoadComponent component={ManageWalkInVisitor} />
                        },
                        {
                            path: "update-details",
                            element: <LoadComponent component={WalkInVisitorUpdateDetailsForm} />
                        }
                    ]
                },
                {
                    path: "visitor-register-detail",
                    children: [
                        {
                            path: "updated",
                            element: <LoadComponent component={VisitorDetailsUpdated} />
                        },
                        {
                            path: "notupdated",
                            element: <LoadComponent component={VisitorDetailsNotUpdated} />
                        },
                        {
                            path: "",
                            element: <LoadComponent component={ManageUpdateVisitorDetails} />
                        }
                    ]
                }
            ]
        },
        {
            /* Protected route */
            path: "/",
            element: <PrivateRoute component={Layout} />,
            children: [
                {
                    path: "dashboard",
                    children: [
                        {
                            path: "home",
                            element: <LoadComponent component={Dashboard} />
                        }
                    ]
                }
            ]
        },
        {
            /* Protected route */
            path: "/",
            element: <PrivateRoute roles={[GlobalValues.superAdministratorRole.id]} component={Layout} />,
            children: [
                {
                    path: "user",
                    children: [
                        {
                            path: "organization/list",
                            element: <LoadComponent component={ListUserLinkedOrganizationManage} />
                        },
                        {
                            path: "organization/manage",
                            element: <LoadComponent component={ManageUserOrganization} />
                        },
                        {
                            path: "list",
                            element: <LoadComponent component={ListUserSuperAdmin} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageUserSuperAdmin} />
                        }
                    ]
                },
                {
                    path: "organization",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListOrganization} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageOrganization} />
                        }
                    ]
                },
                {
                    path: "nda",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListNDA} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageNDA} />
                        }
                    ]
                }
            ]
        },
        {
            /* Protected route */
            path: "/",
            element: <PrivateRoute roles={[GlobalValues.organizationAdministratorRole.id]} component={Layout} />,
            children: [
                {
                    path: "organization/user",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListUserOrganizationAdmin} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageUserOrganizationAdmin} />
                        }
                    ]
                },
                {
                    path: "admin",
                    children: [
                        {
                            path: "establishment/list",
                            element: <LoadComponent component={ListAdminLinkedEstablishmentManage} />
                        },
                        {
                            path: "establishment/manage",
                            element: <LoadComponent component={ManageAdminEstablishment} />
                        }
                    ]
                },
                {
                    path: "employee",
                    children: [
                        {
                            path: "organization/list",
                            element: <LoadComponent component={ListEmployeeLinkedOrganizationManage} />
                        },
                        {
                            path: "organization/manage",
                            element: <LoadComponent component={ManageEmployeeOrganization} />
                        }
                    ]
                },
                {
                    path: "establishment",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListEstablishment} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageEstablishment} />
                        }
                    ]
                },
                {
                    path: "service",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListService} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageService} />
                        },
                        {
                            path: "service-request/update",
                            element: <LoadComponent component={UpdateServiceRequest} />
                        },
                        {
                            path: "slot/list",
                            element: <LoadComponent component={ListServiceSlotManage} />
                        },
                        {
                            path: "slot/manage",
                            element: <LoadComponent component={ManageServiceSlot} />
                        },
                        {
                            path: "exclusion/list",
                            element: <LoadComponent component={ListServiceExclusionManage} />
                        },
                        {
                            path: "exclusion/manage",
                            element: <LoadComponent component={ManageServiceExclusion} />
                        },
                        {
                            path: "service-booking",
                            children: [
                                {
                                    path: "list",
                                    element: <LoadComponent component={ServiceBookingManage} />
                                }
                            ]
                        }
                    ]
                },
                {
                    path: "employeecategory",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListEmployeeCategory} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageEmployeeCategory} />
                        }
                    ]
                },
                {
                    path: "servicesubcategory",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListServiceSubCategory} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageServiceSubCategory} />
                        }
                    ]
                },
                {
                    path: "servicecategory",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListServiceCategory} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageServiceCategory} />
                        }
                    ]
                }
            ]
        },
        {
            /* Protected route */
            path: "/",
            element: <PrivateRoute roles={[GlobalValues.establishmentAdministratorRole.id]} component={Layout} />,
            children: [
                {
                    path: "establishment/user",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListUserManageEstablishmentAdmin} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageUserEstablishmentAdmin} />
                        }
                    ]
                },
                {
                    path: "building",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListBuildingManage} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageBuilding} />
                        }
                    ]
                },
                {
                    path: "unit",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListUnitManage} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageUnit} />
                        },
                        {
                            path: "resident_user/list",
                            element: <LoadComponent component={ListUnitManage} />
                        },
                        {
                            path: "resident_user/manage",
                            element: <LoadComponent component={ManageUnit} />
                        }
                    ]
                },
                {
                    path: "admin-linked/establishment",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListAdminLinkedEstablishment} />
                        }
                    ]
                },
                {
                    path: "establishment/amenity",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListAmenityManage} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageAmenity} />
                        },
                        {
                            path: "amenity-exclusion/list",
                            element: <LoadComponent component={ListAmenityExclusionManage} />
                        },
                        {
                            path: "amenity-exclusion/manage",
                            element: <LoadComponent component={ManageAmenityExclusion} />
                        },
                        {
                            path: "amenity-slot/list",
                            element: <LoadComponent component={ListAmenitySlotManage} />
                        },
                        {
                            path: "amenity-slot/manage",
                            element: <LoadComponent component={ManageAmenitySlot} />
                        },
                        {
                            path: "amenity-booking/list",
                            element: <LoadComponent component={EstablishmentAmenityBookingManage} />
                        }
                    ]
                },
                {
                    path: "establishment/securityGuard",
                    children: [
                        {
                            path: "attendance/list",
                            element: <LoadComponent component={SecurityGuardAttendanceManage} />
                        }
                    ]
                },
                {
                    path: "security_guard",
                    children: [
                        {
                            path: "establishment/list",
                            element: <LoadComponent component={ListSecurityGuardLinkedEstablishmentManage} />
                        },
                        {
                            path: "establishment/manage",
                            element: <LoadComponent component={ManageSecurityGuard} />
                        }
                    ]
                },
                {
                    path: "establishment",
                    children: [
                        {
                            path: "company/list",
                            element: <LoadComponent component={ListCompanyManage} />
                        },
                        {
                            path: "company/manage",
                            element: <LoadComponent component={ManageCompany} />
                        },
                        {
                            path: "company/unit/list",
                            element: <LoadComponent component={ListCompanyUnitManage} />
                        },
                        {
                            path: "company/unit/manage",
                            element: <LoadComponent component={ManageCompanyUnit} />
                        },
                        {
                            path: "emergency-broadcast",
                            element: <LoadComponent component={ManageEmergencyBroadcastEstablishment} />
                        }
                    ]
                },
                {
                    path: "company-employee-admin/company",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListCompanyEmployeeAdminLinkedCompanyManage} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageCompanyEmployeeAdmin} />
                        }
                    ]
                }
            ]
        },
        {
            /* Protected route */
            path: "/",
            element: <PrivateRoute roles={[GlobalValues.companyEmployeeAdminRole.id]} component={Layout} />,
            children: [
                {
                    path: "company-employee-admin-linked/company",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListCompanyEmployeeAdminCompany} />
                        },
                        {
                            path: "user/list",
                            element: <LoadComponent component={ListUserCompanyEmployeeAdminCompanyManage} />
                        },
                        {
                            path: "user/manage",
                            element: <LoadComponent component={ManageUserCompanyEmployeeAdminCompany} />
                        },
                        {
                            path: "employee/list",
                            element: <LoadComponent component={ListEmployeeCompanyEmployeeAdminCompanyManage} />
                        },
                        {
                            path: "employee/manage",
                            element: <LoadComponent component={ManageEmployeeCompanyEmployeeAdminCompany} />
                        },
                        {
                            path: "employee/unit/manage",
                            element: <LoadComponent component={ManageEmployeeUnitCompanyEmployeeAdminCompany} />
                        }
                    ]
                },
                {
                    path: "company",
                    children: [
                        {
                            path: "emergency-broadcast",
                            element: <LoadComponent component={ManageEmergencyBroadcast} />
                        },
                        {
                            path: "visitor/list",
                            element: <LoadComponent component={CompanyVisitorRecordsManage} />
                        },
                        {
                            path: "nda",
                            children: [
                                {
                                    path: "list",
                                    element: <LoadComponent component={ListTermsAndConditionsManage} />
                                },
                                {
                                    path: "manage",
                                    element: <LoadComponent component={ManageTermsAndConditions} />
                                }
                            ]
                        }
                    ]
                },
                {
                    path: "company/amenity",
                    children: [
                        {
                            path: "list",
                            element: <LoadComponent component={ListCompanyAmenityManage} />
                        },
                        {
                            path: "manage",
                            element: <LoadComponent component={ManageCompanyAmenity} />
                        },
                        {
                            path: "amenity-exclusion/list",
                            element: <LoadComponent component={ListCompanyAmenityExclusionManage} />
                        },
                        {
                            path: "amenity-exclusion/manage",
                            element: <LoadComponent component={ManageCompanyAmenityExclusion} />
                        },
                        {
                            path: "amenity-slot/list",
                            element: <LoadComponent component={ListCompanyAmenitySlotManage} />
                        },
                        {
                            path: "amenity-slot/manage",
                            element: <LoadComponent component={ManageCompanyAmenitySlot} />
                        },
                        {
                            path: "amenity-booking/list",
                            element: <LoadComponent component={CompanyAmenityBookingManage} />
                        }
                    ]
                }
            ]
        },
        /* Wildcard for page not found */
        { path: "*", element: <LoadComponent component={PageNotFound} /> }
    ]);
};

export { AllRoutes };
